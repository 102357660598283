// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-fa-q-js": () => import("./../../../src/pages/FaQ.js" /* webpackChunkName: "component---src-pages-fa-q-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/Gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/Order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/Pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-seasonal-js": () => import("./../../../src/pages/Seasonal.js" /* webpackChunkName: "component---src-pages-seasonal-js" */)
}

